<template>
    <div>
        <div :class="{'one-line': !multiline }">
            <slot
                v-if="!asModal && !activeModal || asModal"
                :truncatedValue="truncatedValue"
                :value="value"
                name="truncateContent">
                <template v-if="Array.isArray(truncatedValue) && truncatedValue.length > 0">
                    <ul>
                        <li
                            :key="i"
                            v-for="(item, i) in truncatedValue">
                            {{ item }}
                        </li>
                    </ul>
                </template>
                <template v-else>
                    {{ formatEmptyString(truncatedValue) }}
                </template>
            </slot>
            <slot
                v-if="!asModal && activeModal"
                :value="value"
                name="fullContent">
                <template v-if="Array.isArray(value)">
                    <ul>
                        <li
                            :key="i"
                            v-for="(item, i) in value">
                            {{ item }}
                        </li>
                    </ul>
                </template>
                <template v-else>
                    {{ formatEmptyString(value) }}
                </template>
            </slot>
        </div>
        <slot>
            <template v-if="value && isHide">
                <slot>
                    <template v-if="asModal">
                        <span
                            class="link"
                            @click.prevent.stop="activeModal = true">
                            {{ $t("common.table.views.showMore.more") }}
                        </span>
                    </template>
    
                    <template v-else-if="!asModal && !activeModal">
                        <span
                            v-if="modal"
                            class="link"
                            @click="openModal">
                            {{ $t("common.table.views.showMore.more") }}
                        </span>
                        <LTooltip
                            v-else
                            :label="value"
                            type="is-dark"
                            interactive
                            max-width="400px"
                            position="is-top">
                            <span class="link">
                                {{ $t("common.table.views.showMore.more") }}
                            </span>
                        </LTooltip>
                    </template>
    
                    <template v-else-if="!asModal && activeModal">
                        <span
                            class="link"
                            @click.prevent.stop="activeModal = false">
                            {{ $t("common.table.views.showMore.less") }}
                        </span>
                    </template>
                </slot>
            </template>
        </slot>
    
    
        <ModalBox
            v-if="asModal"
            :is-active.sync="activeModal">
            <template>
                <slot
                    :value="value"
                    name="fullContent">
                    <template v-if="Array.isArray(value)">
                        <ul>
                            <li
                                :key="i"
                                v-for="(item, i) in value">
                                {{ item }}
                            </li>
                        </ul>
                    </template>
                    <template v-else>
                        <span
                            v-if="modal"
                            v-html="value"
                            class="text"></span>
                        <div
                            v-else
                            :class="{'multiline': multiline}">
                            {{ value }}
                        </div>
                    </template>
                </slot>
            </template>
            <template #footer>
                <div class="container">
                    <div class="columns">
                        <div class="column">
                            <b-button
                                class="is-fullwidth is-light-button"
                                @click="activeModal = false">
                                {{ $t("common.table.views.showMore.close") }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </template>
        </ModalBox>
    </div>
</template>

<script>
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import { formatEmptyString } from "@core/filters";
  import Card from "@/components/Common/Card.vue";

  export default {
    name: "ShowMore",
    components: { ModalBox },
    props: {
      data: {
        type: [Array, String],
        default: () => []
      },

      maxString: {
        type: Number,
        default: 60
      },

      maxArray: {
        type: Number,
        default: 1
      },

      asModal: {
        type: Boolean,
        default: true
      },

      multiline: {
        type: Boolean,
        default: false
      },

      modal: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        activeModal: false
      };
    },
    computed: {
      Card () {
        return Card;
      },
      value () {
        if (typeof this.data === "object") {
          if (Array.isArray(this.data)) {
            return this.data;
          }
        }
        return this.data;
      },

      isHide () {
        return Array.isArray(this.value) && this.value.length > 1 ||
          typeof this.value === "string" && this.value.length > this.maxString;
      },

      truncatedValue () {
        if (Array.isArray(this.value)) {
          return this.value.slice(0, this.maxArray);
        } else {
          return this.value && this.value.length > this.maxString ?
            this.value.substr(0, this.maxString)+"..." : this.value;
        }
      }
    },

    methods: {
      openModal () {
        this.$buefy.modal.open({
          parent: this,
          component: Card,
          props: {
            title: this.value,
            headerClass: "is-size-6"
          }
        });
      },

      formatEmptyString
    }
  };
</script>

<style lang="scss" scoped>
    .one-line {
        word-break: break-word;
        white-space: nowrap;
    }

    .link {
        color: $link;
        cursor: pointer;
    }
    .multiline {
        word-break: break-word;
    }
    .text {
      white-space: pre-wrap;
    }
</style>
