<template>
    <b-dropdown
        ref="dropdown"
        append-to-body
        aria-role="menu"
        position="is-bottom-left"
        trap-focus
        @active-change="onDropdownToggle">
        <template #trigger>
            <LTooltip
                :type="tooltipType"
                :label="$t(`admin.offers.capacity.table.dropdown.titleCapacity`)">
                <div
                    class="is-align-items-center"
                    :class="statusColorIcon">
                    <InlineSvg
                        class="is-clickable"
                        :style="stylesIcon"
                        :src="iconRequest"
                        alt="request">
                    </InlineSvg>
                </div>
            </LTooltip>
        </template>

        <form
            v-if="isActive"
            class="comment-form px-4 py-2"
            @submit.prevent="submitFormMethod">
            <h6 class="title is-6 mb-0">
                {{ $t(`admin.offers.capacity.table.dropdown.titleCapacity`) }}
            </h6>

            <div
                v-if="offer && webmaster && status === 'PENDING' || !status"
                class="has-text-grey mt-1">
                {{ $t('admin.offers.capacity.table.dropdown.offer') }}
                <span class="has-text-black">
                    {{ `${ offer.detail.name } [${ offer.intId }]` }}
                </span> <br>
                {{ $t('admin.offers.capacity.table.dropdown.webmaster') }}
                <span class="has-text-black">
                    {{ webmaster.intId }}
                </span>
            </div>

            <span
                v-if="status"
                :class="[statusColor, 'subtitle is-7']">
                {{ $t(`admin.offers.capacity.table.dropdown.status.${ status }`) }}
            </span>

            <span
                v-if="createdAt || updatedAt"
                :class="[statusColor, 'subtitle is-7']">
                {{ createOrUpdateDate }}
            </span>

            <div class="is-flex is-gapless mt-3">
                <b-field :label="$t(`admin.offers.capacity.table.dropdown.newValue`)">
                    <LInput
                        v-model.number="newCapacityValue"
                        :disabled="status === statusEnum.APPROVED || status === statusEnum.REJECTED"
                        min="0"
                        type="number">
                    </LInput>
                </b-field>

                <b-field :label="$t(`admin.offers.capacity.table.dropdown.oldValue`)">
                    <LInput
                        :value="oldValueOrCapacity"
                        disabled
                        type="number">
                    </LInput>
                </b-field>
            </div>

            <b-field
                v-if="!isApproveRequest || isApproveRequest && !status"
                :label="$t(`admin.offers.capacity.table.dropdown.comment`)">
                <LInput
                    v-model="creatorComment"
                    :placeholder="$t(`admin.offers.capacity.table.dropdown.enterText`)"
                    maxlength="1000"
                    minlength="0"
                    :disabled="status === statusEnum.APPROVED || status === statusEnum.REJECTED"
                    :has-counter="false"
                    type="textarea">
                </LInput>
            </b-field>

            <template v-else>
                <b-field
                    :label="$t(`admin.offers.capacity.table.dropdown.commentManager`)"
                    class="comment-creator">
                    <span>
                        <ShowMore
                            :max-string="150"
                            multiline
                            :data="creatorComment">
                        </ShowMore>
                    </span>
                </b-field>

                <b-field
                    v-if="status === statusEnum.PENDING"
                    :label="$t(`admin.offers.capacity.table.dropdown.myComment`)">
                    <LInput
                        v-model="executorComment"
                        :placeholder="$t(`admin.offers.capacity.table.dropdown.enterText`)"
                        maxlength="1000"
                        minlength="0"
                        :has-counter="false"
                        type="textarea">
                    </LInput>
                </b-field>

                <b-field
                    v-else
                    :label="$t(`admin.offers.capacity.table.dropdown.myComment`)"
                    class="comment-creator">
                    <span>
                        <ShowMore
                            :max-string="150"
                            multiline
                            :data="executorComment">
                        </ShowMore>
                    </span>
                </b-field>
            </template>

            <template v-if="isEditRequest && status !== statusEnum.APPROVED && status !== statusEnum.REJECTED">
                <b-button
                    type="is-info"
                    class="mr-3"
                    native-type="submit">
                    {{ submitButtonText }}
                </b-button>

                <b-button
                    v-if="data && status === statusEnum.PENDING"
                    type="is-danger is-light"
                    @click="capacityRequestMethod(actionsEnum.DELETE)">
                    {{ $t(`admin.offers.capacity.table.dropdown.button.deleteRequest`) }}
                </b-button>
            </template>

            <template v-else-if="isApproveRequest && status === statusEnum.PENDING">
                <b-button
                    type="is-info"
                    class="mr-3"
                    @click="capacityRequestMethod(actionsEnum.APPROVE)">
                    {{ $t(`admin.offers.capacity.table.dropdown.button.approve`) }}
                </b-button>

                <b-button
                    type="is-danger is-light"
                    @click="capacityRequestMethod(actionsEnum.REJECT)">
                    {{ $t(`admin.offers.capacity.table.dropdown.button.reject`) }}
                </b-button>
            </template>

            <template v-else>
                <b-button
                    class="mr-3"
                    @click="closeDropdown">
                    {{ $t(`admin.offers.capacity.table.dropdown.button.close`) }}
                </b-button>
                <b-button
                    class="mr-3"
                    type="is-info is-light"
                    @click.prevent.stop="resendRequest">
                    {{ $t(`admin.offers.capacity.table.dropdown.button.newRequest`) }}
                </b-button>
            </template>
        </form>
    </b-dropdown>
</template>

<script>
  import ShowMore from "@/components/Common/ShowMore";
  import InlineSvg from "vue-inline-svg";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { SET_CAPACITY_REQUEST, SET_EMPTY } from "@core/store/mutation-constants";
  import {
    APPROVE_CAPACITY_REQUEST,
    CREATE_CAPACITY_REQUEST,
    DELETE_CAPACITY_REQUEST,
    EDIT_CAPACITY_REQUEST,
    REJECT_CAPACITY_REQUEST,
    UPDATE_CAPACITY_REQUEST
  } from "@core/store/action-constants";
  import { mapActions, mapMutations, mapState } from "vuex";
  import { hasPermissions } from "@core/mixins/permissions";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import { DropdownCapacity } from "@core/mixins";
  import { actionsEnum } from "@core/store/modules/admin/capacityAndBumps/datasets/logRequestEnums.json";

  export default {
    name: "DropdownCapacityRequest",
    mixins: [DropdownCapacity],
    components: { InlineSvg, ShowMore },

    props: {
      oldCapacity: {
        type: Number,
        default: 0
      }
    },

    computed: {
      ...mapFields("admin/capacityAndBumps/capacity/webmastersCapacity/capacityRequest", {
        fields: [
          "newCapacityValue",
          "oldCapacityValue",
          "creatorComment",
          "executorComment",
          "status",
          "createdAt",
          "updatedAt"
        ],
        base: "data",
        action: UPDATE_CAPACITY_REQUEST
      }),

      ...mapState({
        isApproveRequest: ({ auth }) => hasPermissions(["CAPACITY.REQUEST.APPROVE"], auth.userPermissions)
      }),

      oldValueOrCapacity () {
        return this.oldCapacityValue ?? this.oldCapacity;
      }
    },

    methods: {
      ...mapActions("admin/capacityAndBumps/capacity/webmastersCapacity/capacityRequest", {
        CREATE_CAPACITY_REQUEST,
        EDIT_CAPACITY_REQUEST,
        DELETE_CAPACITY_REQUEST,
        APPROVE_CAPACITY_REQUEST,
        REJECT_CAPACITY_REQUEST
      }),

      ...mapMutations("admin/capacityAndBumps/capacity/webmastersCapacity/capacityRequest", {
        SET_CAPACITY_REQUEST,
        SET_EMPTY
      }),

      onDropdownToggle (isActive) {
        const { data } = this;

        this.isActive = isActive;
        if (data && isActive) {
          this.SET_CAPACITY_REQUEST(data);
        } else {
          if (!isActive) return;
          this.SET_EMPTY();
        }
      },

      async capacityRequestMethod (action) {
        const { offerId, webmasterId, moduleNamespace } = this;
        const method = action + "_CAPACITY_REQUEST";
        const namespace = `admin.offers.capacity.table.dropdown.message.${ action }`;

        if (action === actionsEnum.APPROVE) {
          this.$emit("update:localLoading", true);
        }

        this.closeDropdown();
        await actionWithToast(this[method]({ offerId, webmasterId, moduleNamespace }), namespace);
        this.$emit("submit");

        if (action === actionsEnum.APPROVE) {
          const { oldValueOrCapacity: oldCapacity, newCapacityValue: newCapacity } = this;

          this.$emit("update:filledCapacity", { oldCapacity, newCapacity });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    @import "~@/scss/_dropdown-capacity.scss";

    .comment-creator {
        margin-bottom: 1rem;

        ::v-deep {
            .label {
                margin-bottom: 0;
            }

            span {
                display: flex;
                margin-left: 0.6rem;
                font-size: 0.85rem;
            }
        }
    }
</style>